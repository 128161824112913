<template>
    <!-- 大事年鉴 -->
<div id="t5" class="brand-main" >
        <div class="brand-main2">
            <div class="brand-event left">
                <!-- 2020 -->
                <div class="event addbottom" data-role="2020" >
                    <div class="year y1 right"><img src="../../assets/images/2020.png" class="all"></div>
                    <div class="clear"></div>
                    <ul>
                        <li><p>通过教育部教育移动互联网应用程序备案<br/>电子书包学生  教APP备4400125号<br/>电子书包教师  教APP备4400124号</p></li>
                        <li><p>加入国家教育资源公共服务平台</p></li>
                        <li><p>通过2020年“数字校园综合解决方案”</p></li>
                        <li><p>荣获2020中国校园好方案·卓越案例奖</p></li>
                        <li><p>优学派学生平板独家冠名金鹰卡通原创动画片《23号牛乃唐》并夺得收视26连冠</p></li>
                        <li><p>优学派冠名湖南卫视金鹰卡通《龙的传人》第四季，并全程提供技术服务支持</p></li>
                    </ul>

                    <!-- <img src="../../assets/images/2020_1.jpg" class="all"> -->
                </div>
                <!-- 2018 -->
                <div class="event" data-role="2018" style="margin-top:10px">
                    <div class="year y1 right"><img src="../../assets/images/2018.png" class="all"></div>
                    <div class="clear"></div>
                    <ul>
                        <li><p>参与中央电化教育馆“基于网络学习空间的个性化学习模式研究”项目</p></li>
                        <li><p>协办“教育信息化2.0解读与实践探索”交流研讨会</p></li>
                        <li><p>参加第三届全国基础教育信息化应用展示交流活动</p></li>
                        <li><p>优学派再次冠名《龙的传人》第二季并全程提供技术服务支持</p></li>
                        <li><p>优学派智慧教育获奖课例刷新记录，常态应用彰显成效</p></li>
                    </ul>
                    <img src="../../assets/images/2018banner.png" class="all">
                </div>
                <!-- 2016 -->
                <div class="event" data-role="2016">
                    <div class="year y1 right"><img src="../../assets/images/2016.png" class="all"></div>
                    <div class="clear"></div>
                    <ul>
                        <li><p>参与中央电化教育馆“教育大数据分析研究”项目</p></li>
                        <li><p>主研全国教育信息技术研究2016年度重点课题</p></li>
                    </ul>
                    <img src="../../assets/images/2016banner.jpg" class="all">
                </div>

                <!-- 2013-2014 -->
                <div class="event" data-role="2014" style="margin-bottom:180px">
                    <div class="year y1 right"><img src="../../assets/images/2013_2014.png" class="all"></div>
                    <div class="clear"></div>
                    <ul>
                        <li><p>受邀参与教育部《数字化教育装备标准》制定工作</p></li>
                        <li><p>独家冠名央视《中国汉字听写大会》并提供全程技术支持</p></li>
                        <li><p>国家教育部中央电教馆批准优学派承担全国教育信息技术研究“十二五”规划2013年度重点课题</p></li>
                        <li><p>参与起草四川省《中小学电子书包应用系统建设规范》</p></li>
                    </ul>
                    <img src="../../assets/images/2013_2014.jpg" class="all">
                </div>
<!--                &lt;!&ndash;2010&ndash;&gt;-->
<!--                <div class="event" data-role="2010">-->
<!--                    <div class="year y1 right"><img src="../../assets/images/2010.png" class="all"></div>-->
<!--                    <div class="clear"></div>-->
<!--                    <ul>-->
<!--                        <li><p>推出2代搜学技术产品</p></li>-->
<!--                        <li><p>荣膺“中国驰名商标”称号</p></li>-->
<!--                        <li><p>独家承办世界青少年英文拼写大赛中国区比赛</p></li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--                &lt;!&ndash;2008&ndash;&gt;-->
<!--                <div class="event" data-role="2008">-->
<!--                    <div class="year y1 right" style="margin-top: 20px"><img src="../../assets/images/2008.png" class="all"></div>-->
<!--                    <div class="clear"></div>-->
<!--                    <ul>-->
<!--                        <li><p>发布图形计算器产品</p></li>-->
<!--                        <li><p>进入教育培训和全日制教育领域</p></li>-->
<!--                    </ul>-->
<!--                </div>-->
            </div>
            <div class="brand-line left"></div>
            <div class="brand-event right" style="margin-top: 30px">
                <!-- 2019 -->
                <div class="event" data-role="2019" style="margin-top:50px">
                    <div class="year y2 left"><img src="../../assets/images/2019.png" class="all"></div>
                    <div class="clear"></div>
                    <ul>
                        <li><p>与中国教育技术协会达成战略合作</p></li>
                        <li><p>通过并列入广东省中小学校园学习类APP白名单</p></li>
                        <li><p>加入“国家数字教育资源公共服务体系联盟”</p></li>
                        <li><p>优学派荣获“首届中国儿童智能行业十大品牌”称号</p></li>
                        <li><p>优学派冠名《龙的传人》第三季，并全程提供技术服务支持</p></li>
                    </ul>
                    <img src="../../assets/images/2019banner.png" class="all">
                </div>
                <!-- 2017-->
                <div class="event" data-role="2017">
                    <div class="year y2 left"><img src="../../assets/images/2017.png" class="all"></div>
                    <div class="clear"></div>
                    <ul>
                        <li><p>支持教育部中小学“网络学习空间人人通”培训</p></li>
                        <li><p>受邀参加中国国际智慧教育峰会</p></li>
                        <li><p>优学派成为湖南金鹰卡通卫视《龙的传人》独家冠名及全程技术支持提供方</p></li>
                        <li><p>斩获新浪中国教育盛典“2017中国品牌实力教育集团”重磅奖项，是迄今为止行业内唯一一家连续三年获新浪教育权威认可的企业</p></li>
                    </ul>
                    <img src="../../assets/images/2017.jpg" class="all">
                </div>
                <!-- 2015 -->
                <div class="event" data-role="2015">
                    <div class="year y2 left"><img src="../../assets/images/2015.png" class="all"></div>
                    <div class="clear"></div>
                    <ul>
                        <li><p>参加首届国际教育信息化大会</p></li>
                        <li><p>优学派冠名央视少儿大型野外少儿真人秀《最野假期》并提供全程服务支持</p></li>
                        <li><p>第三季《中国汉字听写大会》全国巡回赛技术支持单位</p></li>
                    </ul>
                    <img src="../../assets/images/2015.jpg" class="all">
                </div>
                <!--2012-->
                <div class="event" data-role="2012">
                    <div class="year y2 left"><img src="../../assets/images/2012.png" class="all"></div>
                    <div class="clear"></div>
                    <ul>
                        <li><p>电子书包企业标准发布</p></li>
                    </ul>
                </div>
                <!--2011 -->
                <div class="event" data-role="2011">
                    <div class="year y2 left"><img src="../../assets/images/2011.png" class="all"></div>
                    <div class="clear"></div>
                    <ul>
                        <li><p>举行“云学习”技术平台暨“优学派”产品新闻发布会</p></li>
                    </ul>
                    <img src="../../assets/images/2011.jpg" class="all">
                </div>
<!--                &lt;!&ndash;2009 &ndash;&gt;-->
<!--                <div class="event" data-role="2009">-->
<!--                    <div class="year y2 left"><img src="../../assets/images/2009.png" class="all"></div>-->
<!--                    <div class="clear"></div>-->
<!--                    <ul>-->
<!--                        <li><p>被评为“深圳知名品牌”</p></li>-->
<!--                        <li><p>与美国富兰克林电子出版公司达成战略投资合作，开启了进军全球市场的新局面；在成都建立总部</p></li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--                &lt;!&ndash;2007 &ndash;&gt;-->
<!--                <div class="event" data-role="2007">-->
<!--                    <div class="year y2 left"><img src="../../assets/images/2007.png" class="all"></div>-->
<!--                    <div class="clear"></div>-->
<!--                    <ul>-->
<!--                        <li><p>据中国权威的赛诺调查显示：2007年第一季度市场销售量和销售额均居行业第一</p></li>-->
<!--                        <li><p>同年发布“搜学”技术平台，并推出搜学王系列产品</p></li>-->
<!--                    </ul>-->
<!--                </div>-->
            </div>
            <div class="clear"></div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'developmentHistory',
    data () {
        return {};
    },
};
</script>
<style>
.brand-main{
    width: 100%;
}
.brand-main2{
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 100px;
        display: flex;
    justify-content: center;
    align-items: center;
}
.brand-event{
    width: 548px;
}
.brand-event .event{
    width: 100%;
    margin: 20px 0 80px 0;
}
.brand-event .event p{
    font-size: 18px;
    line-height: 30px;
    color: #4d4d4d;
    text-indent: 0;
    margin: 5px 0;
}
.brand-event .event img:last-child{
    margin-top: 20px;
}
.brand-event .year {
    width: 200px;
    margin-bottom: 10px;
    z-index: 1;
}
.brand-event .y1{
    margin-right: -62px;
}
.brand-event .y2{
    margin-left: -62px;
}
.brand-line{
    width: 104px;
    height: 3042px;
    background: url("../../assets/images/brand38.png") repeat-y center;
    z-index: 0;
}
html,body,div,main,img,ul,ol,li,table,tr,td,header,nav,footer,aside{
    position: relative;
}
body{
    width: 100%;
    font-family: "microsoft yahei";
    color: #1a1a1a;
    margin: 0;
}
.addbottom{
    position: relative;
    bottom: 140px;
}
.bc{
    z-index: -1;
}
.all{
    width: 100%;
}
.left{
    float: left;
}
.right{
    float: right;
}
.clear{
    clear: both;
}

.developmentHistory {
    padding-bottom: 60px;
}
.developmentHistory .item{
    display: flex;
}
.developmentHistory .timeItem{
    /*border-right: 2px solid rgba(232,232,232,1);*/
    width: 50%;
    position: relative;
    padding-top: 17px;
}

.developmentHistory .timeItem:after {
  content: '';
  height: 100%;
  width: 2px;
  background: rgba(232,232,232,1);
  position: absolute;
  right: 0;
  top: 0;
}
.developmentHistory .right{
    justify-content: flex-end;
}
.developmentHistory .timeContent{
    text-align: right;
    padding-right: 32px;
}
.developmentHistory .timeContent p{
    font-size: 14px;
    line-height: 20px;
    color: #787878;
}
.developmentHistory .timeContent p:nth-child(1){
    color: #1E1E1E;
    font-size: 22px;
}
.developmentHistory .timeContent p span{
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: #787878;
    margin-right: 10px;
    border-radius: 50%;
}
.developmentHistory .timeAxis .firstItem .boundary{
    width: 1px;
    height: 50px;
    background: rgba(232,232,232,1);
}
.developmentHistory .timeAxis .firstItem span{
    width: 17px;
    height: 17px;
    border-radius: 50%;
    /*border:1px solid rgba(232,232,232,1);*/
}

.developmentHistory .timeAxis .firstItem span:after{
  content: '';
  height: 100%;
  width: 2px;
  background: rgba(232,232,232,1);
  position: absolute;
  right: 0;
  top: 0;
}
.developmentHistory .timeAxis .firstItem::before,.developmentHistory .timeItem::before{
    content: '';
    width: 18px;
    height: 18px;
    border: 1px solid rgba(232,232,232,1);
    box-sizing: border-box;
    border-radius: 50%;
    position: absolute;
    right: -8px;
    top: -18px;
    z-index: 2;
}
.developmentHistory .timeItem::before{
    top: 0;
    border:none;
    background-image: radial-gradient(#719BF0 10%,white 80%, #719BF0 10%);
}
.developmentHistory .timeAxis .firstItem{
    position: relative;
    /*border-right: 2px  solid rgba(232,232,232,1);*/
    width: 50%;
    display: flex;
    height: 50px;
    justify-content: flex-end;
}
.developmentHistory .timeAxis .firstItem:after{
  content: '';
  height: 100%;
  width: 2px;
  background: rgba(232,232,232,1);
  position: absolute;
  right: 0;
  top: 0;
}
.developmentHistory .timeAxis{
    display: flex;
    flex-direction: column;
    margin-top: 80px;
}
.developmentHistory h1{
    color: #1E1E1E;
    width: 100%;
    display: flex;
    justify-content: center;
}
.developmentHistory{
    margin-top: 80px;
    text-align: center;
}
.developmentHistory .right .timeItem::before{
    left:-10px;;
    right:0;
}
.developmentHistory .right .timeItem{
    border-right: 0;
    /*border-left: 2px solid rgba(232,232,232,1);*/
}

.developmentHistory .right .timeItem:after{
  content: '';
  height: 100%;
  width: 2px;
  background: rgba(232,232,232,1);
  position: absolute;
  left: -2px;
  top: 0;
}
.developmentHistory .right .timeItem{
    width: 50%;
}
.developmentHistory .right .timeContent{
    text-align: left;
    padding-right: 0px;
    padding-left: 32px;
}
</style>
