<!--
 * @LastEditors: zhanglinju
-->
<template>
    <div class="briefIntroduction">
        <h1 style="width: 100%;display: flex;justify-content: center;white-space: nowrap;">深圳市优学天下教育发展股份有限公司</h1>
        <!-- <h3>在线教育平台与服务提供商</h3> -->
        <p style="text-indent: 2rem;margin-bottom:0px;">优学派是深圳市优学天下教育发展股份有限公司旗下品牌，是教育电子行业先行者，主要面向K12教育领域的学生、家长和教师，为其提供专用教育平板等智能互动教育设备。 秉承着“让学习更高效，让教育更公平”的企业使命，公司有效整合硬件、软件、教育资源， 将现代科技与优质教育资源完美结合，为广大学习者提供更具创新和实用的教育产品和运营服务。公司产品作为K12教育的辅助和补充，是智能时代“互联网+教育”的重要学习工具，能够有效促进教育的智能化、个性化、高效化。
        </p>
        <p style="text-indent: 2rem;margin-top:0px;">公司营销渠道覆盖全国31个省、自治区和直辖市，已累计为数百万学生提供优质教育产品和服务。
        </p>
        <div class="CompanyPictures">
            <div class="item">
                <p>理念</p>
                <p>实现现代科技与优质教育资源的完美结合</p>
            </div>
            <div class="item">
                <p>愿景</p>
                <p>让学习更高效，让教育更公平</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'briefIntroduction',
    data () {
        return {};
    },
};
</script>
<style>
.briefIntroduction .CompanyPictures .item:nth-child(2){
    background-image: url('../../assets/images/item1.png');
}
.briefIntroduction .CompanyPictures .item:nth-child(1){
    background-image: url('../../assets/images/item2.png');
}
.briefIntroduction .CompanyPictures .item p:nth-child(1){
    font-size: 22px;
    color: #1E1E1E;
}
.briefIntroduction .CompanyPictures .item p:nth-child(2){
    font-size: 14px;
    color: #787878;
}
.briefIntroduction .CompanyPictures .item p{
    line-height: 0px;
}
.briefIntroduction .CompanyPictures .item{
    background-repeat: no-repeat !important;
    background-position: center;
    background-size: 100% 100% !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 29px;
    background-size: cover;
    background-repeat: no-repeat;
    width: 45%;
    height: 119px;
    text-align: left;
}
.briefIntroduction{
    margin-top: 85px;
}
.briefIntroduction .CompanyPictures{
    display: flex;
    justify-content: space-between;
    margin-top: 55px;
}
.briefIntroduction h3{
    font-size: 14px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.briefIntroduction h1{
    font-size: 22px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.briefIntroduction p{
    line-height: 25px;
    color: #787878;
    font-size: 14px;
    text-align: left;
    width: 100%;
}
.briefIntroduction{
    /*text-align: center;*/
    width: 970px;
    margin: 85px 0 0;
    padding-bottom: 80px;
}
</style>
