
<template>
    <div class="medalOfHonor">
        <h1>荣誉奖项</h1>
        <div class="medalOfHonorImg">
            <img src="@/assets/images/medal2.png" />
        </div>
        <h1>媒体报道</h1>
        <div class="itemBox">
            <div class="items" v-for="(item, index) in videoList" :key="index">
                <img :src="item.image" />
                <img class="play" src="@/assets/images/play.png" @click="play(item.src)" />
                <div class="itemContent">
                    {{ item.text }}
                </div>
            </div>
        </div>
        <div class="videoBox" v-if="bool">
            <video width="100%" controls="controls" @canplaythrough="autoPlay" ref="video">
                <source :src="currentVideo" type="video/mp4" />
            </video>
        </div>
        <div class="model" v-if="bool" @click="bool=false" />
    </div>
</template>

<script>
export default {
    name: 'medalOfHonor',
    data () {
        return {
            bool: false,
            videoList: [
                {
                    image: require('@/assets/images/report1.png'),
                    text: 'CCTV-1《晚间新闻》报道了中共中央政治局委员、国务院副总理孙春兰在甘肃调研期间，参观了敦煌西关小学优学派智慧课堂',
                    src: 'https://yxp-cdn.anoah.com/media/mediaCoverage2.42ff5272.mp4',
                },
                {
                    image: require('@/assets/images/report2.png'),
                    text: 'CCTV 13 报道了优学派智慧教育合作校——山东省德州市经济开发区太阳城小学的课堂应用情况',
                    src: 'https://yxp-cdn.anoah.com/media/mediaCoverage1.35b3a061.mp4',
                },
                {
                    image: require('@/assets/images/report3.png'),
                    text: '中央电视台《中国新闻》权威报道优学派智慧教育合作校——青岛博文小学颠覆传统课堂的全新教学模式',
                    src: 'https://yxp-cdn.anoah.com/media/mediaCoverage3.f8df4ee5.mp4',
                },
                {
                    image: require('@/assets/images/report4.png'),
                    text: '中央电视台CCTV-9纪录频道《育见未来》报道了江西省上饶县石人乡中心小学优学派智慧课堂应用案例',
                    src: 'https://yxp-cdn.anoah.com/media/江西省上饶县石人乡中心小学-CCTV9《育见未来》.mp4',
                },
            ],
            currentVideo: '',
        };
    },
    methods: {
        play (src) {
            this.currentVideo = src;
            this.bool = true;
        },
        autoPlay () {
            this.$refs.video.play();
        },
    },
};
</script>

<style>
video{
    outline:none;
}
.medalOfHonorImg {
  margin: 0 10%;
}
.medalOfHonorImg img {
    max-width: 100%;
}
.model{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    background: #000;
}
.medalOfHonor .videoBox{
    width: 60%;
    z-index: 1000;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.medalOfHonor .itemBox .items .play{
    cursor: pointer;
    position: absolute;
    left: 40%;
    top:25%;
}
.medalOfHonor .itemContent{
    position: absolute;
    left: 20px;
    top: 220px;
    width: 85%;
    text-align: left;
}
.medalOfHonor .itemBox .items{
    position: relative;
    width: 335px;
    height: 341px;
}
.medalOfHonor .itemBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 80px;
    /* width: 1500px; */
    flex-wrap: wrap;
    margin: 0 10%;
}
.medalOfHonor h1{
    color: #000;
    /*text-align: center;*/
    font-size: 22px;
    margin: 30px 0;
    display: flex;
    justify-content: center;
    width: 100%;
}
.medalOfHonor{
    text-align: center;
    width: 100%;
    /*margin-left: 10%;*/
    margin-top: 80px;
}
</style>
