<template>
  <div class="contact-box">
    <p>联系我们</p>
    <div class="map-box">
      <div class="map-info">
        <header class="company-name">深圳市优学天下教育发展股份有限公司成都分公司</header>
        <div
          class="company-info"
          :class="{'address': index === 0}"
          v-for="(item, index) of companyInfo"
          :key="item.id"
        >
           <img :src="item.src" alt="">
           <span :class="{'last': index === companyInfo.length - 1}">{{ item.text }}</span>
        </div>
      </div>
      <div id="baiduMap"></div>
    </div>
  </div>
</template>
<script>
const loadBaiDuMap = function () {
    return new Promise(function (resolve, reject) {
        try {
            resolve(BMap);
        } catch (e) {
            window.onCallback = function () {
                resolve(BMap);
            };
            let bdapi = document.createElement('script');
            bdapi.type = 'text/javascript';
            bdapi.src = `https://api.map.baidu.com/api?v=2.0&ak=AfAtftX31xMxQ4617jGZKWyD&s=1&callback=onCallback`;
            bdapi.onerror = reject;
            document.head.appendChild(bdapi);
        }
    });
};
export default {
    name: 'contact',
    mounted () {
        loadBaiDuMap().then((BMap, BMapLib) => {
            // eslint-disable-next-line
            let map = new BMap.Map('baiduMap');
            // eslint-disable-next-line
            let point = new BMap.Point(104.074884,30.544757);
            map.centerAndZoom(point, 20);
            // 创建标注
            // eslint-disable-next-line
            let marker = new BMap.Marker(point);
            map.addOverlay(marker);
            map.enableScrollWheelZoom();
        });
    },
    data () {
        return {
            companyInfo: [
                {
                    text: '地址：成都市高新区天府大道中段1268号1栋（天府软件园E3座）8层1-12号',
                    id: 1,
                    src: require('@images/address.png'),
                },
                {
                    text: '邮编：610041',
                    id: 2,
                    src: require('@images/zipcode.png'),
                },
                {
                    text: '电话：028-85308888',
                    id: 3,
                    src: require('@images/phone.png'),
                },
                {
                    text: '传真：028-85308884',
                    id: 4,
                    src: require('@images/fax.png'),
                },
            ],
        };
    },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
  .contact-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 80px;
    p {
        color: #1E1E1E;
        font-size: 22px;
        margin: 0;
        padding: 86px 0 35px;
        font-weight: 600;
    }
    .map-box {
        position: relative;
        .map-info {
            z-index: 1;
            padding: 39px  27px;
            border-radius: 12px;
            background-color: #fff;
            position: absolute;
            color: #1E1E1E;
            width: 395px;
            height: 280px;
            top: 116px;
            left: 88px;
            font-weight: 600;
            .company-name {
                font-size: 20px;
                padding-bottom: 30px;
            }
            .company-info {
                font-size: 14px;
                margin-bottom: 10px;
                &.address {
                    display: flex;
                    align-items: center;
                    img {
                        width: 11px;
                        height: 15px;
                    }
                }
                span {
                    display: inline-block;
                    padding-left: 8px;
                    &.last {
                        padding-bottom: 0;
                    }
                }
            }
        }
        #baiduMap {
            width: 1000px;
            height: 500px;;
        }
    }
  }
</style>
