<template>
    <div class="aboutBox">
      <div class="about-banner">
        <div class="about-banner-text">
          <div class="about-banner-text-top">关于我们</div>
          <div class="about-banner-text-bottom">About us</div>
        </div>
      </div>
        <!--<div class="header">-->
            <!--<img src="@/assets/images/bannerAbout.png" />-->
        <!--</div>-->
        <div class="nav">
            <p :class="{nowNav: nowIndex === 1  }" @click="changesNav(1)">公司简介</p>
            <p :class="{nowNav: nowIndex === 2  }" @click="changesNav(2)">发展历程</p>
            <p :class="{nowNav: nowIndex === 3  }" @click="changesNav(3)">荣誉奖项</p>
            <p :class="{nowNav: nowIndex === 4  }" @click="changesNav(4)">联系我们</p>
        </div>
        <div class="templateDiv">
            <component :is="name"></component>
        </div>
    </div>
</template>
<script>
import briefIntroduction from '@/modules/about/briefIntroduction';
import developmentHistory from '@/modules/about/developmentHistory';
import medalOfHonor from '@/modules/about/medalOfHonor';
import contact from '@/modules/about/contact';
export default {
    data () {
        return {
            nowIndex: 1,
            name: 'briefIntroduction',
        };
    },
    methods: {
        changesNav (index) {
            switch (index) {
                case 1:
                    this.name = 'briefIntroduction';
                    break;
                case 2:
                    this.name = 'developmentHistory';
                    break;
                case 3:
                    this.name = 'medalOfHonor';
                    break;
                case 4:
                    this.name = 'contact';
                    break;
            }
            this.nowIndex = index;
        },
    },
    components: {
        developmentHistory,
        briefIntroduction,
        medalOfHonor,
        contact,
    },
};
</script>
<style lang="scss" rel="stylesheet/scss">
.aboutBox .nav .nowNav,.aboutBox .nav p:hover{
    color: #4278E6;
    border-bottom: 2px solid #4278E6;
    box-sizing: border-box;
}
.aboutBox .nav p{
    margin: 0 25px;
    cursor: pointer;
    color: #000000;
    height: 56px;
    line-height: 56px;
    font-size: 16px
}
.aboutBox .nav{
    background-color: #F2F3F5;
    display: flex;
    justify-content: center;
    align-items: center;
}
.aboutBox .header img{
    width: 100%;
    height: 375px;
}

.about-banner {
  width: 100%;
  height: 375px;
  background-image: url(~@/assets/images/bannerAbout.png);
  background-repeat: no-repeat;
  background-size: 2293px 375px;
  background-position: center;
  display: flex;
  align-items: center;
  &-text {
    margin-bottom: 60px;
    margin-left: 15%;
    &-top {
      font-size:32px;
      color:#ffffff;
      line-height:32px;
      margin-bottom: 28px;
    }
    &-bottom {
      font-size:16px;
      color:#ffffff;
      line-height:16px;
    }
  }
}
.templateDiv {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
